import moment from "moment";
import Tooltip from "rc-tooltip";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import {
  useAssignSolarPlantToCustomerSpaceClusterMutation,
  useLazyAdminViewSpaceClusterConsumptionByYearAndMonthQuery,
  useLazyAdminViewSpaceClusterEnergyByYearQuery,
  useLazyGetAllSpaceClusterOfConsumerQuery,
  useLazyGetSpaceClusterEnergyWithComparisonQuery,
  useLazyViewPowerConsumersWithHighConsumptionOfConsumerQuery,
  useLazyViewPowerGenerationDataOfConsumerQuery,
  useRemoveSolarPlantToCustomerSpaceClusterMutation,
} from "../../redux/api/consumer/consumerAPI";
import SpaceDeviceFilters from "../../shared/components/space-device-filters/space-device-filters";
import AdminViewSpaceClusterConsumptionByYearAndMonthResponseDTO from "../../shared/oversight-core/dtos/response-dtos/admin-view-space-cluster-consumption-by-year-and-month-response-dto";
import AdminViewSpaceClusterEnergyByYearResponseDTO from "../../shared/oversight-core/dtos/response-dtos/admin-view-space-cluster-energy-by-year-response-dto";
import {
  ISpaceClusterWithPowerGeneratorIds,
  ViewScheduledPowerConsumersSortedByEnergyResponseDTO,
  ViewSpaceClusterEnergyWithComparisonResponseDTO,
} from "../../shared/oversight-core/dtos/response-dtos/dashboard-api-dto";
import ViewEnergyGenerationResponseDTO from "../../shared/oversight-core/dtos/response-dtos/view-energy-generation-response-dto";
import { EConsumptionValueComparison } from "../../shared/oversight-core/enums/consumption-comparison";
import { EConsumptionViewTypes } from "../../shared/oversight-core/enums/consumption-view-types";
import { EEnergyViewType } from "../../shared/oversight-core/enums/energy-viewType";
import { OvstErrorCode } from "../../shared/oversight-core/enums/ovst-error-codes";
import { EProviders } from "../../shared/oversight-core/enums/providers";
import { EScheduleViewType } from "../../shared/oversight-core/enums/schedule-view-type";
import { ESortOrder } from "../../shared/oversight-core/enums/sort-order";
import { EUsageDataFrom } from "../../shared/oversight-core/enums/usage-data-from";
import { ConsumptionDataView } from "../../shared/oversight-core/interfaces/entities/consumption-data-view";
import { ISpaceView } from "../../shared/oversight-core/interfaces/entities/space";
import IExtendedPowerConsumers from "../../shared/oversight-core/interfaces/extended-energy-consumers";
import IMonthlyEnergySummaryView from "../../shared/oversight-core/interfaces/monthly-energy-summary-view";
import UserDashboardFilters from "../../shared/oversight-core/interfaces/user-dashboard-filters";
import UsageCard from "../../shared/oversight-core/shared-components/dashboard-widgets/usage/usage-card";
import HighPowerDevices from "../../shared/oversight-core/shared-pages/dashboard-widgets/high-power-devices/high-power-devices";
import AppDatePicker from "../../shared/oversight-core/ui-elements/app-date-picker/app-date-picker";
import AppSelect, {
  Option,
} from "../../shared/oversight-core/ui-elements/app-select/app-select";
import AppTabs from "../../shared/oversight-core/ui-elements/app-tabs/app-tabs";
import AreaChart from "../../shared/oversight-core/ui-elements/area-chart/area-chart";
import AppButton from "../../shared/oversight-core/ui-elements/buttons/app-button/app-button";
import Card from "../../shared/oversight-core/ui-elements/card/card";
import MaterialIcon from "../../shared/oversight-core/ui-elements/material-icon/material-icon";
import SpinnerModal from "../../shared/oversight-core/ui-elements/spinner/spinner";
import UserDetails from "../../shared/oversight-core/ui-elements/user-details/user-details";
import { capitalizeFirstLetter } from "../../shared/oversight-core/utils/capitalize-first-letter";
import { offsetToString } from "../../shared/oversight-core/utils/offsetToString";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../shared/oversight-core/utils/toast";
import DeviceListView from "./device-list-view/device-list-view";
import InverterLinkModal from "./models/inverter-link-modal/inverter-link-modal";
import InverterUnlinkModal from "./models/inverter-unlink-modal/inverter-unlink-modal";
import UsageInfoModal from "./models/usage-info-moda";
import Schedule from "./schedule/schedule";
import SpaceView from "./space-view/space-view";
import styles from "./user-dashboard.module.scss";

interface ChartData {
  consumptionType: "UNITS" | "BILL";
  year: 0;
  months: string[];
  solar: number[];
  grid: number[];
  manual: number[];
  semiAuto: number[];
}

const scheduleViewType = [
  {
    label: "Usage from Both Manual And Semi-Automated",
    value: EScheduleViewType.ALL,
  },
  {
    label: "Usage from Manual Schedule",
    value: EScheduleViewType.MANUAL,
  },
  {
    label: "Usage from Semi-Automated Schedule",
    value: EScheduleViewType.SEMI_AUTOMATED,
  },
];

const defaultUsage = {
  energyBill: 0,
  energyInUnits: 0,
};

const defaultFilters: UserDashboardFilters = {
  selectedFilterSpace: {
    id: "",
    clusterId: "",
    name: "",
  },
  selectedDeviceType: "",
  selectedSmartControllerType: undefined,
  badgeValue: 0,
};

const AdminUserView = () => {
  const { state: user } = useLocation();

  const [spaceClusters, setSpaceClusters] = useState<
    ISpaceClusterWithPowerGeneratorIds[]
  >([]);
  const [selectedSpaceCluster, setSelectedSpaceCluster] =
    useState<ISpaceClusterWithPowerGeneratorIds>();
  const [selectedSpace, setSelectedSpace] = useState<{
    clusterId: string;
    spaceId: string;
  }>({ clusterId: "", spaceId: "" });
  const [semiAutomatedUsageThisMonth, setSemiAutomatedUsageThisMonth] =
    useState({
      ...defaultUsage,
    });
  const [semiAutomatedUsageLastMonth, setSemiAutomatedUsageLastMonth] =
    useState({
      ...defaultUsage,
    });
  const [actualThisMonth, setActualThisMonth] = useState({
    ...defaultUsage,
  });
  const [actualLastMonth, setActualLastMonth] = useState({
    ...defaultUsage,
  });
  const [consumers, setConsumers] = useState<IExtendedPowerConsumers[] | null>(
    []
  );
  const [selectedButton, setSelectedButton] = useState(1);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [chartDataAvailable, setChartDataAvailable] = useState(false);
  const [chartData, setChartData] = useState<ChartData>({
    consumptionType: "UNITS",
    year: 0,
    months: [],
    solar: [],
    grid: [],
    manual: [],
    semiAuto: [],
  });
  const [activeTab, setActiveTab] = useState<number>(1);
  const [selectedScheduleViewType, setSelectedScheduleViewType] =
    useState<Option>(scheduleViewType[0]);
  const [comparisonEnergyThisMonth, setComparisonEnergyThisMonth] =
    useState<EConsumptionValueComparison>(
      EConsumptionValueComparison.NOT_MEASURABLE
    );
  const [comparisonEnergyLastMonth, setComparisonEnergyLastMonth] =
    useState<EConsumptionValueComparison>(
      EConsumptionValueComparison.NOT_MEASURABLE
    );
  const [actualUsageDataFromThisMonth, setActualUsageDataFromThisMonth] =
    useState<EUsageDataFrom>(EUsageDataFrom.TELEMETRY);
  const [actualUsageDataFromLastMonth, setActualUsageDataFromLastMonth] =
    useState<EUsageDataFrom>(EUsageDataFrom.TELEMETRY);
  const [showUsageInfoModal, setShowUsageInfoModal] = useState(false);
  const [monthlyEnergySummaryView, setMonthlyEnergySummaryView] = useState<
    IMonthlyEnergySummaryView[]
  >([]);
  const [filters, setFilters] = useState<UserDashboardFilters>({
    ...defaultFilters,
  });
  const [showInventerLinkModal, setShowInventerLinkModal] = useState(false);
  const [selectedPlantId, setSelectedPlantId] = useState("");
  const [showInventerUnlinkModal, setShowInventerUnlinkModal] = useState(false);
  const [updateCurrentStatus, setUpdateCurrentStatus] = useState(false);

  const [triggerGetSpaceClusters, { isFetching: isFetchingSpaceClusters }] =
    useLazyGetAllSpaceClusterOfConsumerQuery();
  const [
    triggerGetSemiAutomatedUsage,
    { isFetching: isFetchingSemiAutomatedUsage },
  ] = useLazyGetSpaceClusterEnergyWithComparisonQuery();
  const [triggerGetActualUsage, { isFetching: isFetchingActualUsage }] =
    useLazyAdminViewSpaceClusterConsumptionByYearAndMonthQuery();
  const [
    triggerViewPowerConsumersWithHighConsumption,
    { isFetching: isFetchingHighestConsumption },
  ] = useLazyViewPowerConsumersWithHighConsumptionOfConsumerQuery();
  const [
    triggerViewPowerGenerationData,
    { isFetching: isFetchingEnergyGeneration },
  ] = useLazyViewPowerGenerationDataOfConsumerQuery();
  const [
    triggerViewActualConsumptionByYearQuery,
    {
      data: getEnergyConsumption,
      isSuccess: isSuccessEnergyConsumption,
      isFetching: isFetchingActualConsumptionByYear,
    },
  ] = useLazyAdminViewSpaceClusterEnergyByYearQuery();
  const [assignSolarPlant, { isLoading: isLoadingAssignSolarPlant }] =
    useAssignSolarPlantToCustomerSpaceClusterMutation();
  const [removeSolarPlant, { isLoading: isLoadingRemoveSolarPlant }] =
    useRemoveSolarPlantToCustomerSpaceClusterMutation();

  const assignSolar = (
    spaceClusterId: string,
    consumerId: string,
    plantId: string
  ) => {
    assignSolarPlant({
      spaceClusterId: spaceClusterId,
      customerId: consumerId,
      plantId: plantId,
    })
      .unwrap()
      .then(() => {
        showSuccessMessage("Solar Plant Connected Successfully");
        setShowInventerLinkModal(false);
        setUpdateCurrentStatus((ps) => !ps);
      })
      .catch(() => {
        showErrorMessage("Solar Plant Connected Unsuccessfully");
      });
  };

  const disconnectSolarPlant = (
    spaceClusterId: string,
    consumerId: string,
    plantId: string
  ) => {
    removeSolarPlant({
      spaceClusterId: spaceClusterId,
      customerId: consumerId,
      plantId: plantId,
    })
      .unwrap()
      .then(() => {
        showSuccessMessage("Solar Plant Disconnected Successfully");
        setShowInventerUnlinkModal(false);
        setUpdateCurrentStatus((ps) => !ps);
      })
      .catch(() => {
        showErrorMessage("Solar Plant Disconnected Unsuccessfully");
      });
  };

  useEffect(() => {
    if (user?.id) {
      setSpaceClusters([]);
      triggerGetSpaceClusters(user.id)
        .unwrap()
        .then((res) => {
          setSpaceClusters(
            res.spaceClusters
              .slice()
              .sort((a, b) => a.label.trim().localeCompare(b.label.trim())) ||
              []
          );
        })
        .catch(() => {
          setSelectedSpace({
            clusterId: "",
            spaceId: "",
          });
          showErrorMessage("Sorry, An error occurred");
        });
    }
  }, [user?.id, triggerGetSpaceClusters, updateCurrentStatus]);

  useEffect(() => {
    if (selectedSpaceCluster?.id && selectedDate) {
      const viewEnergyAs: EEnergyViewType =
        selectedButton === 1 ? EEnergyViewType.UNIT : EEnergyViewType.BILL;
      const viewConsumptionAs: EConsumptionViewTypes =
        EConsumptionViewTypes.ALL;
      const viewScheduleAs: EScheduleViewType =
        selectedScheduleViewType.value as EScheduleViewType;

      setChartData((ps) => ({
        ...ps,
        grid: [],
        solar: [],
        manual: [],
        semiAuto: [],
      }));

      triggerViewActualConsumptionByYearQuery({
        spaceClusterId: selectedSpaceCluster.id,
        consumerId: user?.id,
        year: selectedDate.getFullYear(),
        viewEnergyAs,
        viewConsumptionAs,
        viewScheduleAs,
        zoneOffset: offsetToString(moment().utcOffset()),
      })
        .unwrap()
        .then((res: AdminViewSpaceClusterEnergyByYearResponseDTO) => {
          setMonthlyEnergySummaryView(res.orderedMonthlyEnergySummary);
        })
        .catch((error) => {
          console.log(error);
        });

      if (
        selectedButton === 1 &&
        selectedSpaceCluster &&
        selectedSpaceCluster.powerGeneratorIds?.length > 0
      ) {
        triggerViewPowerGenerationData({
          powerGeneratorId:
            (selectedSpaceCluster &&
              selectedSpaceCluster.powerGeneratorIds[0]) ||
            "",
          year: selectedDate.getFullYear(),
          consumerId: user?.id,
        })
          .unwrap()
          .then((res: ViewEnergyGenerationResponseDTO) => {
            setChartData((ps) => {
              ps.solar =
                res.generatedEnergy?.map((ge) => +ge.toFixed(2)) ||
                Array.from({ length: 12 }, () => 0);
              ps.months = res.months || [];
              return { ...ps };
            });
          })
          .catch(() => {
            showErrorMessage("Sorry, An error occurred");
          });
      }
      setChartData((ps) => {
        ps.solar = Array.from({ length: 12 }, () => 0);
        return { ...ps };
      });
    } else {
      setChartData({
        consumptionType: "UNITS",
        year: 0,
        months: [],
        solar: [],
        grid: [],
        manual: [],
        semiAuto: [],
      });
    }
  }, [
    triggerViewActualConsumptionByYearQuery,
    triggerViewPowerGenerationData,
    selectedSpaceCluster?.id,
    user?.id,
    selectedSpaceCluster?.powerGeneratorIds,
    selectedDate,
    selectedButton,
    selectedSpaceCluster,
    selectedScheduleViewType.value,
  ]);

  useEffect(() => {
    if (spaceClusters.length > 0) {
      const isFound = spaceClusters.find(
        (sc) => sc.id === selectedSpaceCluster?.id
      );

      setSelectedSpaceCluster(isFound || spaceClusters[0]);

      setSelectedSpace(
        isFound
          ? {
              clusterId: isFound.id,
              spaceId: isFound.rootSpace.id,
            }
          : {
              clusterId: spaceClusters[0].id,
              spaceId: spaceClusters[0].rootSpace.id,
            }
      );
    }
  }, [spaceClusters, selectedSpaceCluster?.id]);

  useEffect(() => {
    if (selectedSpaceCluster?.id && user?.id) {
      triggerGetActualUsage({
        spaceClusterId: selectedSpaceCluster.id,
        year: new Date().getFullYear(),
        month: new Date().getMonth() + 1,
        consumerId: user?.id,
      })
        .unwrap()
        .then(
          (res: AdminViewSpaceClusterConsumptionByYearAndMonthResponseDTO) => {
            setActualThisMonth({
              energyBill: res.energy.energyBill,
              energyInUnits: res.energy.energyInUnits,
            });
            setActualLastMonth({
              energyBill: res.previousEnergy.energyBill,
              energyInUnits: res.previousEnergy.energyInUnits,
            });
            setComparisonEnergyThisMonth(
              res.comparedToPreviousMonthConsumedEnergy
            );
            setActualUsageDataFromThisMonth(res.usageDataFrom);
          }
        )
        .catch(() => {
          setActualThisMonth({
            energyBill: 0,
            energyInUnits: 0,
          });
          setActualLastMonth({
            energyBill: 0,
            energyInUnits: 0,
          });
          setComparisonEnergyThisMonth(
            EConsumptionValueComparison.NOT_MEASURABLE
          );
        });
    }
  }, [triggerGetActualUsage, selectedSpaceCluster?.id, user?.id]);

  useEffect(() => {
    if (selectedSpaceCluster?.id && user?.id) {
      triggerGetActualUsage({
        spaceClusterId: selectedSpaceCluster.id,
        year: new Date().getFullYear(),
        month: new Date().getMonth(),
        consumerId: user?.id,
      })
        .unwrap()
        .then(
          (res: AdminViewSpaceClusterConsumptionByYearAndMonthResponseDTO) => {
            setComparisonEnergyLastMonth(
              res.comparedToPreviousMonthConsumedEnergy
            );
            setActualUsageDataFromLastMonth(res.usageDataFrom);
          }
        )
        .catch(() => {
          setComparisonEnergyLastMonth(
            EConsumptionValueComparison.NOT_MEASURABLE
          );
        });
    }
  }, [triggerGetActualUsage, selectedSpaceCluster?.id, user?.id]);

  useEffect(() => {
    if (selectedSpaceCluster?.id && user?.id) {
      triggerGetSemiAutomatedUsage({
        spaceClusterId: selectedSpaceCluster.id,
        consumerId: user?.id,
        zoneOffset: offsetToString(moment().utcOffset()),
      })
        .unwrap()
        .then((res: ViewSpaceClusterEnergyWithComparisonResponseDTO) => {
          setSemiAutomatedUsageThisMonth({
            energyBill: res.currentMonthEnergyView.energyBill,
            energyInUnits: res.currentMonthEnergyView.energyInUnits,
          });
          setSemiAutomatedUsageLastMonth({
            energyBill: res.previousMonthEnergyView.energyBill,
            energyInUnits: res.previousMonthEnergyView.energyInUnits,
          });
        })
        .catch(() => {
          setSemiAutomatedUsageThisMonth({
            energyBill: 0,
            energyInUnits: 0,
          });
          setSemiAutomatedUsageLastMonth({
            energyBill: 0,
            energyInUnits: 0,
          });
        });
    }
  }, [triggerGetSemiAutomatedUsage, selectedSpaceCluster?.id, user?.id]);

  useEffect(() => {
    if (isSuccessEnergyConsumption && getEnergyConsumption) {
      const data: ConsumptionDataView =
        getEnergyConsumption.orderedMonthlyEnergySummary.reduce(
          (
            previousValue: ConsumptionDataView,
            currentValue: IMonthlyEnergySummaryView
          ) => {
            previousValue?.months?.push(currentValue.monthName);
            previousValue?.actualConsumption?.push(
              selectedButton === 1
                ? currentValue.energySummaryView?.consumedEnergySummary
                    ?.energyInUnits || 0
                : currentValue.energySummaryView?.consumedEnergySummary
                    ?.energyBill || 0
            );
            previousValue?.manualConsumption?.push(
              selectedButton === 1
                ? currentValue.energySummaryView?.manualScheduleEnergySummary
                    ?.energyInUnits || 0
                : currentValue.energySummaryView?.manualScheduleEnergySummary
                    ?.energyBill || 0
            );
            previousValue?.semiAutoConsumption?.push(
              selectedButton === 1
                ? currentValue.energySummaryView
                    ?.semiAutomatedScheduleEnergySummary?.energyInUnits || 0
                : currentValue.energySummaryView
                    ?.semiAutomatedScheduleEnergySummary?.energyBill || 0
            );

            return previousValue;
          },
          {
            months: [],
            actualConsumption: [],
            manualConsumption: [],
            semiAutoConsumption: [],
          }
        );

      setChartData((ps) => {
        ps.grid = data.actualConsumption.map((ac) => +ac.toFixed(2));
        ps.manual = data.manualConsumption.map((mc) => +mc.toFixed(2));
        ps.semiAuto = data.semiAutoConsumption.map((sac) => +sac.toFixed(2));
        ps.months = data.months;
        return { ...ps };
      });
    } else {
      setChartData((ps) => {
        ps.grid = Array.from({ length: 12 }, () => 0);
        ps.manual = Array.from({ length: 12 }, () => 0);
        ps.semiAuto = Array.from({ length: 12 }, () => 0);
        return { ...ps };
      });
    }
  }, [isSuccessEnergyConsumption, getEnergyConsumption, selectedButton]);

  useEffect(() => {
    if (selectedSpaceCluster?.id) {
      setConsumers([]);
      triggerViewPowerConsumersWithHighConsumption({
        clusterId: selectedSpaceCluster?.id,
        sortingOrder: ESortOrder.ASC,
        limit: 6,
      })
        .unwrap()
        .then((res: ViewScheduledPowerConsumersSortedByEnergyResponseDTO) => {
          const extendedPowerConsumers =
            res.powerConsumers &&
            res.powerConsumers.map((powerConsumer) => {
              const pathObject = res?.devicePaths[powerConsumer.id];
              let path = ``;
              pathObject &&
                pathObject.ancestorSpaces
                  .slice()
                  .reverse()
                  .forEach((hierarchy, hierarchyIndex) => {
                    path +=
                      pathObject.ancestorSpaces.length - 1 === hierarchyIndex
                        ? `${hierarchy.name}`
                        : `${hierarchy.name} > `;
                  });
              return { ...powerConsumer, path };
            });
          setConsumers(extendedPowerConsumers || null);
        })
        .catch((error) => {
          if (
            error.status !== 404 &&
            error.ovstErrorCode !== OvstErrorCode.OVST_0028
          ) {
            showErrorMessage("Sorry, An error occurred");
            return;
          }
        });
    }
  }, [triggerViewPowerConsumersWithHighConsumption, selectedSpaceCluster?.id]);

  const monthsList = chartData.months.map((m) => capitalizeFirstLetter(m));

  const sendSelectedSpace = (space: ISpaceView) => {
    setSelectedSpace({
      clusterId: space.clusterId,
      spaceId: space.id,
    });
  };

  useEffect(() => {
    if (selectedSpaceCluster)
      setFilters((ps) => {
        return {
          selectedFilterSpace: {
            clusterId: selectedSpaceCluster?.id,
            id: selectedSpaceCluster?.rootSpace.id,
            name: selectedSpaceCluster?.label,
          },
          selectedDeviceType: ps.selectedDeviceType,
          selectedSmartControllerType: ps.selectedSmartControllerType,
          badgeValue: ps.badgeValue,
        };
      });
  }, [selectedSpaceCluster]);

  useEffect(() => {
    setSelectedSpace({
      clusterId: filters.selectedFilterSpace.clusterId,
      spaceId: filters.selectedFilterSpace.id,
    });
  }, [filters]);

  useEffect(() => {
    const energyArrays = [
      chartData.grid,
      chartData.manual,
      chartData.semiAuto,
      chartData.solar,
    ];

    const isDataAvailable = energyArrays.some((array) =>
      array.some((units) => units > 0)
    );

    setChartDataAvailable(isDataAvailable);
  }, [chartData]);

  return (
    <>
      <Row>
        <Col>
          <Card>
            <UserDetails
              name={user?.name}
              avatar={user?.avatar}
              contact={user?.contact}
              email={user?.email}
              username={user?.username}
            />
          </Card>
        </Col>
      </Row>
      <div className={`${styles.userDashboardOverflow} container-white mt-3`}>
        <Row className="align-items-center justify-content-between">
          <Col
            xs={12}
            lg={12}
            xl={8}
            xxl={6}
            className={styles.billingSpaceDropDown}
          >
            <Row className="align-items-center justify-content-between justify-content-xl-start">
              <Col className="col-12 col-sm-6">
                {spaceClusters.length > 0 && (
                  <AppSelect
                    selectedValue={{
                      value: selectedSpaceCluster?.id || "",
                      label: selectedSpaceCluster?.label || "",
                      data: selectedSpaceCluster,
                    }}
                    options={
                      spaceClusters
                        ? spaceClusters.map((spaceCluster) => {
                            return {
                              value: spaceCluster.id,
                              label: spaceCluster.label,
                              data: spaceCluster,
                            };
                          })
                        : []
                    }
                    onChangeOption={(selectedOption) => {
                      setSelectedSpaceCluster(
                        selectedOption.data as ISpaceClusterWithPowerGeneratorIds
                      );
                      setSelectedSpace({
                        clusterId: selectedOption.data?.id || "",
                        spaceId: selectedOption.data?.rootSpace.id || "",
                      });
                    }}
                  />
                )}
              </Col>
              {spaceClusters.length > 0 && activeTab === 1 && (
                <>
                  <Col className="col-auto ps-0">
                    {selectedSpaceCluster?.plantId ? (
                      <Row
                        className={`${styles.linkedPlant} p-2 align-items-center cursor-pointer mx-0`}
                        onClick={() => setShowInventerUnlinkModal(true)}
                      >
                        <Col className="col-auto pe-0">
                          <MaterialIcon icon="link" color="#69768B" />
                        </Col>
                        <Tooltip
                          placement="top"
                          trigger={["hover"]}
                          overlay={
                            <>
                              <div>{selectedSpaceCluster.plantId}</div>
                              <div>{selectedSpaceCluster.plantName}</div>
                              <div>{selectedSpaceCluster.plantAddress}</div>
                            </>
                          }
                        >
                          <Col
                            className={`${styles.textTruncate} text-light font-size-12 font-weight-500 ps-1`}
                          >
                            {selectedSpaceCluster.plantId}
                          </Col>
                        </Tooltip>
                      </Row>
                    ) : (
                      <Tooltip
                        placement="top"
                        trigger={["hover"]}
                        overlay={<>Connect Plant</>}
                      >
                        <AppButton
                          iconName="link"
                          text=""
                          variant="transparentWithBorder"
                          size="medium"
                          iconOnly
                          onClick={() => setShowInventerLinkModal(true)}
                          className="px-4"
                        />
                      </Tooltip>
                    )}
                  </Col>
                </>
              )}
              {spaceClusters.length > 0 && activeTab === 2 && (
                <Col className="col-auto">
                  <SpaceDeviceFilters
                    setFilters={setFilters}
                    filters={filters}
                    selectedSpaceCluster={selectedSpaceCluster}
                  />
                </Col>
              )}
            </Row>
          </Col>
          <Col
            xs={12}
            sm={6}
            lg={5}
            xl={4}
            xxl={3}
            className={`${styles.activeTab} mt-3 mt-xl-0`}
          >
            <AppTabs
              buttons={[
                {
                  buttonName: "Info",
                  selectedColor: "purpleColor",
                  id: 1,
                },
                {
                  buttonName: "Devices",
                  selectedColor: "purpleColor",
                  id: 2,
                },
                {
                  buttonName: "Schedules",
                  selectedColor: "purpleColor",
                  id: 3,
                },
              ]}
              onChange={(id) => {
                setActiveTab(id);
              }}
              selectedTabId={activeTab}
            />
          </Col>
        </Row>
        {spaceClusters.length > 0 ? (
          activeTab === 1 ? (
            <>
              <Row className="mt-4">
                <Col
                  className="col-12 col-xl-4"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <div className="h-50">
                    <UsageCard
                      title="Usage This Month"
                      actualUsage={actualThisMonth}
                      scheduleUsage={semiAutomatedUsageThisMonth}
                      isFetching={
                        isFetchingSemiAutomatedUsage || isFetchingActualUsage
                      }
                      consumptionValueComparison={comparisonEnergyThisMonth}
                      usageDateFrom={actualUsageDataFromThisMonth}
                      predictedUsageText="Still learning..."
                    />
                  </div>
                  <div className="h-50 mt-3">
                    <UsageCard
                      title="Usage Last Month"
                      actualUsage={actualLastMonth}
                      scheduleUsage={semiAutomatedUsageLastMonth}
                      isFetching={
                        isFetchingSemiAutomatedUsage || isFetchingActualUsage
                      }
                      consumptionValueComparison={comparisonEnergyLastMonth}
                      usageDateFrom={actualUsageDataFromLastMonth}
                      predictedUsageText="Did not predict"
                    />
                  </div>
                </Col>
                <Col xs={12} lg={12} xl={8} className="mt-3 mt-xl-0">
                  <HighPowerDevices
                    consumers={consumers || null}
                    title="Devices with Highest Energy Consumption this Month"
                    isFetching={isFetchingHighestConsumption}
                  />
                </Col>
              </Row>
              <div className="container-white mt-4">
                <Row className="align-items-center">
                  <Col
                    xs={{ order: 2 }}
                    lg={{ order: 1 }}
                    className="col-12 col-lg-5 mt-3 mt-lg-0"
                  >
                    <AppSelect
                      options={scheduleViewType}
                      selectedValue={selectedScheduleViewType}
                      onChangeOption={(selectedUsageOption) => {
                        setSelectedScheduleViewType(selectedUsageOption);
                      }}
                      fontSize="12"
                      fontWeight="400"
                    />
                  </Col>
                  <Col
                    xs={{ order: 1 }}
                    lg={{ order: 2 }}
                    className="col-12 col-lg-7"
                  >
                    <Row className="align-items-center justify-content-md-end">
                      <Col className="col-12 col-sm-6 col-lg-5 col-xxl-2 mt-3 mt-sm-0">
                        <AppButton
                          text="More Info"
                          size="medium"
                          onClick={() => setShowUsageInfoModal(true)}
                          className="font-size-12 font-weight-500 px-2"
                          variant="transparentWithBorder"
                        />
                      </Col>
                      {user?.serviceProvider === EProviders.LECO && (
                        <Col className="col-12 col-sm-6 col-lg-5 col-xxl-3 mt-3 mt-sm-0">
                          <AppTabs
                            buttons={[
                              {
                                buttonName: "Units",
                                selectedColor: "purpleColor",
                                id: 1,
                              },
                              {
                                buttonName: "Bill",
                                selectedColor: "purpleColor",
                                id: 2,
                              },
                            ]}
                            onChange={(id) => setSelectedButton(id)}
                            selectedTabId={selectedButton}
                          />
                        </Col>
                      )}

                      <Col className="col-12 col-sm-6 col-xxl-4 mt-3 mt-sm-0">
                        <AppDatePicker
                          dateFormat="yyyy"
                          selectedDate={selectedDate}
                          onChange={(date) => setSelectedDate(date)}
                          monthYearPicker={false}
                          yearPicker={true}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <div className="position-relative mt-4">
                  {selectedButton === 1 ? (
                    selectedScheduleViewType.value === EScheduleViewType.ALL ? (
                      <AreaChart
                        borderColor1="#18A1AA"
                        borderColor2="#CB343D"
                        borderColor3="#6729CC"
                        borderColor4="#29CC39"
                        backgroundColor1="#DFFDFB4D"
                        backgroundColor2="#CC292920"
                        backgroundColor3="#8476DE20"
                        backgroundColor4="#29CC3917"
                        label1="Usage from Grid"
                        label2="Semi-Automated Schedule Usage"
                        label3="Manual Schedule Usage"
                        label4="Solar Generation"
                        yAxesUnit="Units"
                        labels={monthsList}
                        data1={chartData.grid}
                        data2={chartData.semiAuto}
                        data3={chartData.manual}
                        data4={chartData.solar}
                        hasForthDataSet={true}
                        isChartDataAvailable={chartDataAvailable}
                      />
                    ) : selectedScheduleViewType.value ===
                      EScheduleViewType.MANUAL ? (
                      <AreaChart
                        borderColor1="#18A1AA"
                        borderColor2="#6729CC"
                        borderColor3="#29CC39"
                        backgroundColor1="#DFFDFB4D"
                        backgroundColor2="#8476DE20"
                        backgroundColor3="#29CC3917"
                        label1="Usage from Grid"
                        label2="Manual Schedule Usage"
                        label3="Solar Generation"
                        yAxesUnit="Units"
                        labels={monthsList}
                        data1={chartData.grid}
                        data2={chartData.manual}
                        data3={chartData.solar}
                        hasThirdDataSet={true}
                        isChartDataAvailable={chartDataAvailable}
                      />
                    ) : selectedScheduleViewType.value ===
                      EScheduleViewType.SEMI_AUTOMATED ? (
                      <AreaChart
                        borderColor1="#18A1AA"
                        borderColor2="#CB343D"
                        borderColor3="#29CC39"
                        backgroundColor1="#DFFDFB4D"
                        backgroundColor2="#CC292920"
                        backgroundColor3="#29CC3917"
                        label1="Usage from Grid"
                        label2="Semi-Automated Schedule Usage"
                        label3="Solar Generation"
                        yAxesUnit="Units"
                        labels={monthsList}
                        data1={chartData.grid}
                        data2={chartData.semiAuto}
                        data3={chartData.solar}
                        hasThirdDataSet={true}
                        isChartDataAvailable={chartDataAvailable}
                      />
                    ) : null
                  ) : selectedScheduleViewType.value ===
                    EScheduleViewType.ALL ? (
                    <AreaChart
                      borderColor1="#18A1AA"
                      borderColor2="#CB343D"
                      borderColor3="#6729CC"
                      backgroundColor1="#DFFDFB4D"
                      backgroundColor2="#CC292920"
                      backgroundColor3="#8476DE20"
                      label1="Usage from Grid"
                      label2="Semi-Automated Schedule Usage"
                      label3="Manual Schedule Usage"
                      yAxesUnit="Rs."
                      labels={monthsList}
                      data1={chartData.grid}
                      data2={chartData.semiAuto}
                      data3={chartData.manual}
                      hasThirdDataSet={true}
                      isChartDataAvailable={chartDataAvailable}
                    />
                  ) : selectedScheduleViewType.value ===
                    EScheduleViewType.MANUAL ? (
                    <AreaChart
                      borderColor1="#18A1AA"
                      borderColor2="#6729CC"
                      backgroundColor1="#DFFDFB4D"
                      backgroundColor2="#8476DE20"
                      label1="Usage from Grid"
                      label2="Manual Schedule Usage"
                      yAxesUnit="Rs."
                      labels={monthsList}
                      data1={chartData.grid}
                      data2={chartData.manual}
                      hasSecondDataSet={true}
                      isChartDataAvailable={chartDataAvailable}
                    />
                  ) : selectedScheduleViewType.value ===
                    EScheduleViewType.SEMI_AUTOMATED ? (
                    <AreaChart
                      borderColor1="#18A1AA"
                      borderColor2="#CB343D"
                      backgroundColor1="#DFFDFB4D"
                      backgroundColor2="#CC292920"
                      label1="Usage from Grid"
                      label2="Semi-Automated Schedule Usage"
                      yAxesUnit="Rs."
                      labels={monthsList}
                      data1={chartData.grid}
                      data2={chartData.semiAuto}
                      hasSecondDataSet={true}
                      isChartDataAvailable={chartDataAvailable}
                    />
                  ) : null}
                  <SpinnerModal
                    show={
                      isFetchingEnergyGeneration ||
                      isFetchingActualConsumptionByYear
                    }
                  />
                </div>
              </div>
            </>
          ) : activeTab === 2 ? (
            <>
              {filters.selectedFilterSpace.id &&
              (filters.selectedDeviceType ||
                filters.selectedSmartControllerType) ? (
                <DeviceListView filters={filters} />
              ) : (
                <SpaceView
                  selectedSpace={selectedSpace}
                  onSpaceSelect={(space) => {
                    setSelectedSpace((ps) => {
                      return { ...ps, spaceId: space.id };
                    });
                  }}
                  sendSelectedSpace={sendSelectedSpace}
                />
              )}
            </>
          ) : (
            <Schedule
              selectedSpace={selectedSpace}
              sendSelectedSpace={sendSelectedSpace}
            />
          )
        ) : (
          <>
            {!isFetchingSpaceClusters && (
              <div className="container-dash mt-4">
                <Row>
                  <Col className="text-center text-light font-size-12">
                    The user has not created any billing spaces.{" "}
                  </Col>
                </Row>
              </div>
            )}
          </>
        )}
        <UsageInfoModal
          show={showUsageInfoModal}
          onClose={() => setShowUsageInfoModal(false)}
          monthlyEnergySummeryView={monthlyEnergySummaryView}
        />
        <InverterLinkModal
          show={showInventerLinkModal}
          onClose={() => setShowInventerLinkModal(false)}
          onCancel={() => setShowInventerLinkModal(false)}
          setSelectedPlantId={setSelectedPlantId}
          selectedPlantId={selectedPlantId}
          onConfirm={() => {
            if (user && selectedSpaceCluster && selectedPlantId) {
              assignSolar(selectedSpaceCluster.id, user?.id, selectedPlantId);
            }
          }}
          isLoading={isLoadingAssignSolarPlant}
        />
        <InverterUnlinkModal
          show={showInventerUnlinkModal}
          onClose={() => setShowInventerUnlinkModal(false)}
          onCancel={() => setShowInventerUnlinkModal(false)}
          onConfirm={() => {
            if (user && selectedSpaceCluster) {
              disconnectSolarPlant(
                selectedSpaceCluster.id,
                user?.id,
                selectedSpaceCluster?.plantId
              );
            }
          }}
          isLoading={isLoadingRemoveSolarPlant}
          spaceClusterName={selectedSpaceCluster?.label || ""}
          plantName={selectedSpaceCluster?.plantName || ""}
        />
      </div>
    </>
  );
};

export default AdminUserView;
